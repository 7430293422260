import React from "react";
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import logo from '../assets/images/logo.png';

import watapp from '../assets/images/watapp.png';

import chaticon from '../assets/images/chat-icon.png';

import rightIcon from '../assets/images/right_icon_g.png';
import MobileMenu from "./mobileMenu";
import Button from 'react-bootstrap/Button';
import WaveVideo from '../assets/images/ballet.mp4';

class Header extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: true,
      cement: true,
      rmx: false,
      mbm:false
    };
    this.onScroll = this.onScroll.bind(this);
    this.changeMenu = this.changeMenu.bind(this);
    this.redirectAnnual = this.redirectAnnual.bind(this);
  }
  componentDidMount() {
      new WOW.WOW({
      live: false
      }).init();

      document.addEventListener('scroll', () => {
        const scrollTop = window.scrollY < 28;
        if (scrollTop !== this.state.scrollTop) {
          this.onScroll(scrollTop);
        }
      });
    
    }
    
    onScroll(scrollTop) {
      this.setState({ scrollTop });
    }

    changeMenu(varname) {
      switch (varname) {
        case "cement":
          this.setState({ cement: true, rmx:false, mbm:false });
          break;
        case "rmx":
          this.setState({ cement: false, rmx:true, mbm:false });
          break;
        case "mbm":
          this.setState({ cement: false, rmx:false, mbm:true });
          break;
        default: return;
      }
    }
    reditredTo(){
      alert('hi')
    }
    redirectAnnual(){
      window.open('https://annualreport.nuvoco.com', '_blank');
    }
   render(){
    const { cement, rmx, mbm } = this.state;
    const openInNewTab = url => {
      // 👇️ setting target to _blank with window.open
      window.open(url, '_blank', 'noopener,noreferrer');
    };
    return(
      <Container className="header">
      <Navbar bg="light" expand="lg"  className={this.state.scrollTop ? 'up' : 'fixed'}>     
        
      <Navbar.Brand  href="/" className="wow fadeInDown" data-wow-delay="0.3s">
          <img 
          src={logo}
          className="d-inline-block align-top"
          alt="logo"
        />
        </Navbar.Brand>
        
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto" >                

                <Nav.Link as={Link} to="/about-us" className="wow fadeInLeft">
                    About Us
                    <ul className="sub_menu about_menu">
                        <li>
                          <Nav.Link as={Link} to="our-journey">Our Journey <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="our-brand-story">Our Brand Story <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="vision-mission-and-values">Vision, Mission & Values <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="board-of-directors">Board of Directors <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="management-team">Management Team <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="quality-and-innovation">Quality and Innovation <img src={rightIcon} alt=''/></Nav.Link>
                        </li>
                    </ul>
                </Nav.Link>

                <Nav.Link className="wow fadeInLeft">
                  Our Businesses
                  <ul className="sub_menu">
                        <li>
                          <Nav.Link as={Link} to="top-cement-company">Cement <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="ready-mix-concrete">Ready-Mix Concrete <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="modern-building-materials">Modern Building Material <img src={rightIcon} alt=''/></Nav.Link>                     
                        </li>
                    </ul>
                </Nav.Link>

                <div className="mege_menu product_mm">
                  <Nav.Link as={Link} to="our-brands" className="wow fadeInLeft">Our Brands</Nav.Link>
                  <div className="mm_tb">                      
                        <div className="d-flex">
                          <ul className="pr_dt">
                              <li onClick={() => this.changeMenu("cement")} className={cement ? 'active' : ''}>Cement</li>
                              <li onClick={() => this.changeMenu("rmx")} className={rmx ? 'active' : ''}>Ready-Mix Concrete</li>
                              <li onClick={() => this.changeMenu("mbm")} className={mbm ? 'active' : ''}>MBM</li>
                          </ul>
                        <div className="mm_ct">
                          { cement ? <ul>
                              <li>
                                <h3>Concreto</h3>
                                <Nav.Link as={Link} to="products/concreto-cement">Concreto</Nav.Link>
                                <Nav.Link as={Link} to="products/concreto-uno">Concreto Uno</Nav.Link>
                               {/*  <Nav.Link as={Link} to="products/concreto-green-cement">Concreto Green</Nav.Link>  */}  
                                <h3 className="mt-3">Double Bull Cement</h3>
                                <Nav.Link as={Link} to="products/double-bull-master-cement">Master</Nav.Link> 
                                <Nav.Link as={Link} to="products/double-bull-ppc">PPC</Nav.Link>  
                                <Nav.Link as={Link} to="products/double-bull-psc">PSC</Nav.Link> 
                                {/* <Nav.Link as={Link} to="products/double-bull-subh-cement">Subh</Nav.Link>                               */}
                              </li>
                              <li>
                                <h3>Duraguard</h3>
                                <Nav.Link as={Link} to="products/duraguard-cement">Duraguard</Nav.Link> 
                                <Nav.Link as={Link} to="products/duraguard-microfiber-cement">Microfiber</Nav.Link> 
                                <Nav.Link as={Link} to="products/duraguard-rapidx-cement">RapidX</Nav.Link>                                 
                                {/* <Nav.Link as={Link} to="products/duraguard-waterseal-cement">Waterseal</Nav.Link>  */}
                                <Nav.Link as={Link} to="products/duraguard-xtra-cement">Xtra</Nav.Link>  
                                <Nav.Link as={Link} to="products/duraguard-f2f">F2F</Nav.Link>                               
                                {/* <Nav.Link as={Link} to="products/duraguard-xtra-cement">Xtra Cement</Nav.Link>  */}                               
                                
                              </li>
                              <li>
                                <h3>Infracem</h3>
                                {/* <Nav.Link as={Link} to="products/double-bull-opc-43">Double Bull - OPC 43</Nav.Link> 
                                <Nav.Link as={Link} to="products/double-bull-opc-53">Double Bull - OPC 53</Nav.Link> */}
                                <Nav.Link as={Link} to="products/infracem-cement">Infracem</Nav.Link>
                                {/* <Nav.Link as={Link} to="products/double-bull-procem-cement">Double Bull Procem</Nav.Link> */}                                 
                              </li>
                              
                              <li>
                              <h3>Nirmax</h3>
                                <Nav.Link as={Link} to="products/nirmax-ppc">Nirmax</Nav.Link>                                
                                <Nav.Link as={Link} to="products/nirmax-opc">Nirmax 43</Nav.Link> 
                                <Nav.Link as={Link} to="products/nirmax-opc">Nirmax 53</Nav.Link>

                                <h3 className="mt-3">Premium Slag</h3>
                                <Nav.Link as={Link} to="products/p-s-c">PSC</Nav.Link>
                                
                              </li>
                          </ul> : ''}

                          { rmx ? 
                            <ul className="mm_rmx">
                               <li>
                                <h3>Artiste</h3>
                                <Nav.Link as={Link} to="products/artiste-bare">Bare</Nav.Link>
                                <Nav.Link as={Link} to="products/artiste-coarse">Coarse</Nav.Link>
                                <Nav.Link as={Link} to="products/artiste-engrave">Engrave</Nav.Link>
                                <Nav.Link as={Link} to="products/artiste-hue">Hue</Nav.Link>
                                <Nav.Link as={Link} to="products/artiste-lumos">Lumos</Nav.Link>
                                <Nav.Link as={Link} to="products/artiste">Signature</Nav.Link>                                
                              </li>
                              <li>
                                <h3>Concreto</h3>
                                <div className="d-flex">
                                  <Nav.Link as={Link} to="products/concreto-agile">Agile</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-agile-flow">Agile Flow</Nav.Link> 
                                  <Nav.Link as={Link} to="products/concreto-corrosafe-plus">Corrosafe</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-cwt-plus">CWT Plus</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-easyfille">Easyfille</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-endura">Endura</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-fluide%C2%A0">Fluide</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-glyde">Glyde</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-instante">Instante</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-lente">Lente</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-permadure">Permadure</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-polibre">Polibre</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-regletherme">Regletherme</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-robuste">Robuste</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-steelibre">Steelibre</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-xlite">Xlite</Nav.Link>
                                  <Nav.Link as={Link} to="products/concreto-xlite-structure">Xlite Structure</Nav.Link>
                              
                                </div>
                              </li>
                             
                              <li>
                                <h3>Ecodure</h3>
                                <Nav.Link as={Link} to="products/ecodure">Ecodure</Nav.Link>
                                <Nav.Link as={Link} to="products/ecodure-plus">Plus</Nav.Link>
                                <Nav.Link as={Link} to="products/ecodure-prime">Prime</Nav.Link>

                                <h3 className="mt-3">InstaMix</h3>
                                <Nav.Link as={Link} to="products/instamix">InstaMix</Nav.Link>
                                <Nav.Link as={Link} to="products/instamix-microne">Microne</Nav.Link>
                                <Nav.Link as={Link} to="products/instamix-mortare">Mortare</Nav.Link>  

                                <h3 className="mt-3">X-CON</h3>
                                <Nav.Link as={Link} to="products/x-con">X-CON</Nav.Link>                             

                              </li>
                            </ul>

                           : ''}

                           {mbm ?
                            <ul className="mm_rmx">
                                  <h3 className="w-100">Zero M</h3>
                              <li className="z_m" style={{minWidth:'230px'}}>

                                  <h3 className="mt-0 f15">Waterproofing Solutions</h3>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-iwc">IWC +</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-2k">2K</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-multipurpose-latex-expert">Multipurpose Latex Expert</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-multipurpose-acrylic-expert">Multipurpose Acrylic Expert</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-roof-shield">Roof shield</Nav.Link>
                                  <h3 className="mt-3 f15">Repair Solutions</h3>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-cleaner">Tile Cleaner</Nav.Link>
                              </li>       
                              <li className="z_m" style={{minWidth:'230px'}}>
                                  <h3 className="mt-0 f15">Tile & Stone Fixing Solutions</h3>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t1">Tile Adhesive T1</Nav.Link> 
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t2">Tile Adhesive T2</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t3">Tile Adhesive T3</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t4">Tile Adhesive T4</Nav.Link>
                                  {/* <Nav.Link as={Link} to="products/zero-m-tile-adhesive-t5">Tile Adhesive T5</Nav.Link> */}
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-epoxy-tile-grout">Epoxy Tile Grout</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-grout">Tile Grout</Nav.Link>
                              </li>                 
                              <li className="z_m" style={{minWidth:'230px'}}>
                                  <h3 className="mt-0 f15">Surface Preparation Solutions</h3>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-wall-putty">Wall Putty</Nav.Link> 
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-dry-plaster">Dry Plaster</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-dry-concrete">Dry Concrete</Nav.Link>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-block-jointing-mortar">Block Joining Mortar</Nav.Link>
                                  <h3 className="mt-3 f15">Ancillary Solutions</h3>
                                  <Nav.Link as={Link} to="products/nuvoco-zero-m-cover-block">Cover Block</Nav.Link>
                              </li>
                              
                            </ul>
                           : ''}
                      </div>
                  </div>
                </div>
                </div>
                
                
                <Nav.Link as={Link} to="/sustainability" className="wow fadeInLeft">
                  Sustainability      
                  <ul className="sub_menu sustainability-menu">
                      <li>
                        <Nav.Link as={Link} to="/environment">Environment <img src={rightIcon} alt=''/></Nav.Link>
                        <Nav.Link as={Link} to="/social">Social <img src={rightIcon} alt=''/></Nav.Link>                    
                        <Nav.Link as={Link} to="/governance">Governance - ESG Profile<img src={rightIcon} alt=''/></Nav.Link>                    
                        <Nav.Link as={Link} to="/health-and-safety">Health and Safety<img src={rightIcon} alt=''/></Nav.Link>               
                      </li>
                  </ul>           
                </Nav.Link>
                <Nav.Link className="wow fadeInLeft">
                  Investor Relations                  
                  <ul className="sub_menu">
                      <li>
                        <Nav.Link as={Link} to="performance-highlights"> Performance Highlights <img src={rightIcon} alt=''/></Nav.Link>
                        <Nav.Link as={Link} to="investors-corner">Investors Corner <img src={rightIcon} alt=''/></Nav.Link>                    
                        <Nav.Link as={Link} to="corporate-governance">Corporate Governance <img src={rightIcon} alt=''/></Nav.Link>                    
                        <Nav.Link as={Link} to="annual-reports">Annual Reports <img src={rightIcon} alt=''/></Nav.Link>
                        <a className="nav-link" onClick={() => this.redirectAnnual()} >Annual Report FY2023-24 Microsite</a>
                        {/* <Nav.Link as={Link} to="">Annual Reports Website<img src={rightIcon} alt=''/></Nav.Link>                     */}
                      </li>
                  </ul>
                </Nav.Link>
                <Nav.Link as={Link} to="/life-at-nuvoco" className="wow fadeInLeft">
                  Life at Nuvoco
                  <ul className="sub_menu">
                      <li>
                          <Nav.Link as={Link} to={{ pathname: "/vision-mission-and-values", hash: "#ourValue"}}>Our Values <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to={{ pathname: "/life-at-nuvoco", hash: "#ourPeople"}}>Our People<img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to="our-employee-value-proposition">Our Employee Value Proposition <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link as={Link} to={{pathname: "/life-at-nuvoco", hash: "#awardandrecognition"}}>Awards and Recognitions <img src={rightIcon} alt=''/></Nav.Link>
                          <Nav.Link onClick={() => openInNewTab('https://career44.sapsf.com/career?company=nuvocovist')}>
                            Join Our Team <img src={rightIcon} alt=''/></Nav.Link>                  
                    
                      </li>
                  </ul>
                </Nav.Link>        
                <Nav.Link as={Link} to="media/Press-Releases" className="wow fadeInLeft">Media</Nav.Link>
                <Nav.Link as={Link} to="contact-us" className="wow fadeInLeft">Contact Us </Nav.Link>
              </Nav>
              <MobileMenu />
            </Navbar.Collapse>
        </Navbar>
        <div className="right_btn wow fadeInRigth">                    
                    <Button variant="danger" as={Link} to="/contact-us">Contact Us</Button> &nbsp;&nbsp;&nbsp;
                    <Button  variant="success" href="https://nuvonirmaan.com/" target="_blank">Nuvo Nirmaan</Button>

                </div>

                 
                <div className="waapp">
                
              <a href="https://wa.me/919830017272?text=hi" target="_blank"><img className="imgwa" src={watapp} alt='' /></a>                  
              {/* <a href="javascript:void(0)" data-ifrmae="https://bot.surbo.io/widget-bot/5ec6b70a4e581e24787a6799?srb_1=&amp;srb_2=&amp;srb_3="><img className="chat_icon" src={chaticon} alt='' /></a>  */}
 
   
         </div>
    </Container>
    
    );
   }
}

export default Header;