import React, {useEffect,useState}  from "react";
import {Container, Row, Col, Breadcrumb,Button, Form, Alert, Card} from 'react-bootstrap';
import { Helmet } from "react-helmet";

import banner from '../assets/images/envirmentBanner.png';
import bannermobile from '../assets/images/envirmentBanner-mobile.jpg';
import aboutBannerLogo from '../assets/images/envirmenticon.png';
import envirimg1 from '../assets/images/envirimg1.png'
import envirimg2 from '../assets/images/envirimg2.png'
import envirimg3 from '../assets/images/envirimg3.png'
import envirimg4 from '../assets/images/envirimg4.png'
import envirimg5 from '../assets/images/envirimg5.png'
import envirimg6 from '../assets/images/envirimg6.png'
import envirimg7 from '../assets/images/envirimg7.png'
import envirimg8 from '../assets/images/envirimg8.png'
import envirimg9 from '../assets/images/envirimg9.png'
import envirimg10 from '../assets/images/envirimg10.png'
import envirimg11 from '../assets/images/envirimg11.png'
import envirimg12 from '../assets/images/envirimg12.png'
import envirimg13 from '../assets/images/envirimg13.png'
import envirimg14 from '../assets/images/envirimg14.png'

import arroing from '../assets/images/arrofull.png';
import { environmentApi,  environmentsearchApi} from "../redux";
import { connect } from "react-redux";
import { imgUrl } from "../redux/config";
import pdfIcon from '../assets/images/pdf_icon.png';


let IsData = false;
let status = null;
const Environment = ({environmentApi, environment, environmentsearchApi, environmentsearch}) => {    
    useEffect(() => {
        environmentApi();
        environmentsearchApi(10,1,'Arasmeta Cement Plant');
	}, []);

    if (Object.keys(environment).length) {
        status = environment.data.status;
        if(environment.data.status === 200){
            const data = {...environment.data};
            var plantList = JSON.parse(data.env_plants);
            //var monthList = JSON.parse(data.env_month);
            var yearList = JSON.parse(data.env_year);
        } 
        
    }
   
    const [plant, setPlant] = useState('Arasmeta Cement Plant');
    const [year, setYear] = useState(10);
    const [month, setMonth] = useState(1);
 

    const changeYear = (type) =>{
        setYear(type.target.value);
    }
    const changePlant = (type) =>{
        setPlant(type.target.value);
    }
  /*   const changeMonth = (type) =>{
        setMonth(type.target.value);
    }
 */
   const setData = () =>{
        environmentsearchApi(year, month, plant)
    }

    if (Object.keys(environmentsearch).length) {
        IsData = true;
        const pdfData = {...environmentsearch.data};
        var pdfList = JSON.parse(pdfData.env_report);
    }

        return(
            <>
            {(IsData && (

                <div className="crop_gov enviromentPage">
                  <Helmet>
                  <title>Cement Impacts On Environment | Environment Assesment Report</title>
                  <meta name="keywords" description="" />
                 <meta name="description" content="Building contributing to a sustainable world is an integral part of Nuvoco s vision we work towards realizing it through various means We work on our environment policies to fulfill our commitments" />
                </Helmet>
                    <div className="banner text-center position-relative">
                        <img className='desktopbannerinner' src={banner} alt="" />
                        <img className='mobilebannerinner' src={bannermobile} alt="" />
                        <article className='position-absolute text-center'>
                            <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                            <h1 className="text-uppercase">Environment</h1>
                        </article>
                        <div className='breadouter'>
                            <div className='container'>
                                <Breadcrumb className='mb-0 py-2'>
                                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Environment 
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>

                    <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>Product Excellence</h2>
          <p>
          Green buildings are need of the changing construction ecosystem. Traditional cement highly depends on natural resources like limestone and water, causing more environmental harm. To conserve precious natural raw materials, alternative raw materials have become a key focus area for us. All our plants constantly strive to increase the pozzolanic additions like Fly Ash and Slag to make PPC and PSC variants of cement to decrease greenhouse gas (GHG) emissions. Our innovative and sustainable product Ecodure (range of green concrete that can reduce the carbon emissions up to 60% in comparison with standard OPC mix)  are testimony to the efforts made towards meeting our sustainability agenda ‘Protect Our Planet (POP)’.These products are developed for a sustainable and circular economy in the construction industry by closing the material cycle that enhances the conservation of natural resources.</p>
          <p>
          </p>
        
          <Row className='topbx my-5'>
            <div className="d-flex justify-content-center row">
            {/* <Col md={6} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg1} />
                </div>
                <Card.Body>
                  <Card.Title>Upto 25%</Card.Title>
                  <Card.Text>
                    Less water consumption
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col> */}
            <Col md={6} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg2} />
                </div>
                <Card.Body>
                  <Card.Title>Upto 60%</Card.Title>
                  <Card.Text>
                  Reduced Carbon Emissions
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            </div>
          </Row>
      
        </Container>
      </div>
      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>Energy Efficiency</h2>
          <p>
          Energy efficiency refers to using less energy while increasing the output of a manufacturing unit. To reduce our environmental footprint and meet the energy demand, we’ve adopted smart energy sourcing activities and built our own capabilities. All our integrated units have an alternate fuel capability and further investing in alternative fuel material handling facilities at two of our plants to help us stay on track with our circular economy goals. In the clinkerisation and grinding units, we are achieving the optimum level of specific heat consumption (SHC) and specific power consumption (SPC). Our 1.5 MW solar power plants, 150 MW captive power plants and 44.7 MW waste heat recovery systems help us maintain our commitment to alternative energy resources.  </p>
          <p>
          </p>
        
          <Row className='topbx my-5'>
            <div className="d-flex justify-content-center row">
            <Col md={4} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg3} />
                </div>
                <Card.Body>
                  <Card.Title>5.3 MWp</Card.Title>
                  <Card.Text>
                  Solar Power Plants
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg4} />
                </div>
                <Card.Body>
                  <Card.Title>150 MW</Card.Title>
                  <Card.Text>
                   Captive Power Plants
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg5} />
                </div>
                <Card.Body>
                  <Card.Title>44.7 MW</Card.Title>
                  <Card.Text> 
                   Waste Heat Recovery Systems
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            </div>
          </Row>
        </Container>
      </div>
      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>Water Management</h2>
          <p>
          Without water, there is no world. To conserve water, we have adopted 5R concept (Reduce, Reuse, Recycle, Recharge and Respect) and ensure that it is strictly enforced. We commit to reducing water use by 5% each year through 5 R approach. All our cement plant uses process wastewater generated from captive power plants. The wastewater generated from captive power plants is treated at our sewage treatment plants. Swage-treated water is utilized in cement plants' process requirements, dust suppression, and domestic usages. This ensures us in closed-looped water consumption and to achieve Zero Liquid Discharge from our operations. We also aim to reduce our reliance on freshwater with improvement in rainwater harvesting structures and systems.   </p>
          <p>
          </p>
        

          <Row className='topbx'>
                        <div className="m5section">
                          <div className="sticakerm5">5R</div>
                          <div className="droplinemain">
                            <img className='arroing' src={arroing} alt="" /></div>
                        </div>

                        <div className="d-flex justify-content-center row">
                        <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg6} />
                </div>
                <Card.Body>
                  <Card.Title>Reduce</Card.Title>
                 
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg7} />
                </div>
                <Card.Body>
                  <Card.Title>Reuse</Card.Title>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg8} />
                </div>
                <Card.Body>
                  <Card.Title>Recycle</Card.Title>
                 
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg9} />
                </div>
                <Card.Body>
                  <Card.Title>Recharge</Card.Title>
                
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg10} />
                </div>
                <Card.Body>
                  <Card.Title>Respect</Card.Title>
                
                </Card.Body>
              </Card>
            </Col>
                        </div>
                      </Row>

        
      




        </Container>
      </div>

      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>Waste Management</h2>
          <p>
          Through our initiatives war on waste and educating the public about the need to eliminate such waste, we make waste management processes as effective as possible at every level of the manufacturing process. We repurpose hazardous and non-hazardous waste generated from various industries as alternative fuels to produce clinker and alternative materials to produce blended cements. Our goal is to implement efficient Waste Management practices across organization. Wastes generated at our manufacturing locations are collected through a FOUR BIN system i.e. Bins for each type of waste coded with green, red, yellow and blue. These segregated wastes are stored at designated place. Hazardous waste and wastes like medical waste, plastic waste etc. disposed off as per statutory requirements through Govt. approved agencies.   </p>
          <p>
          </p>
        
          {/* <Row className='topbx my-5'>
            <div className="d-flex justify-content-center row">
            <Col md={5} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg11} />
                </div>
                <Card.Body>
                  <Card.Title>CPCB</Card.Title>
                  <Card.Text>
                    Approved Recyclers For Waste Oil Disposal
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
        

         
            </div>
          </Row> */}
        </Container>
      </div>

      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>Carbon Footprint</h2>
          <p>
          In line with our corporate environmental policy, ‘climate protection and sustainable resource conservation lays the foundation for our future development’, we are taking several initiatives mainly on Clinker Factor (C/K), Alternative Fuel (Thermal Substitution Ratio - TSR) and Renewable Energy (RE). We have achieved one of the best blended cement ratios in the industry, with a C/K ratio of 1.8 and working on further improving the C/K ratio by ramping up composite cement volume. We use alternative fuels (AF) and alternative raw materials (ARM) that provide efficient and green alternatives to fossil fuels and natural raw materials, reducing emissions. Our facilities in Bhiwani and Chittorgarh have solar power facilities and are further installed in Bhabua and Jajpur Grinding units. We also plant many native trees to expand our green belt, use alternative resources for sand and aggregate for concrete preparation and electric-powered and aluminium-bodied trucks for transporting goods while increasing fuel efficiency.  </p>
          <p>
          </p>
        
          <Row className='topbx my-5'>
            <div className="d-flex justify-content-center row">
            <Col md={4} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg12} />
                </div>
                <Card.Body>
                  <Card.Title>C/K</Card.Title>
                  <Card.Text>
                    Clinker Factor
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg13} />
                </div>
                <Card.Body>
                  <Card.Title>TSR</Card.Title>
                  <Card.Text>
                   Alternative Fuel
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3}>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={envirimg14} />
                </div>
                <Card.Body>
                  <Card.Title>RE</Card.Title>
                  <Card.Text>
                   Renewable Energy
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>         
            </div>
          </Row>
        </Container>
      </div>

               <Container className='py-5'>
         
               <h2 className='grad-heading'>Environment Reports</h2>
        <div className="text-center">
                        { status === 200 &&
                            <>
                                <Row>
                                    <Col md={12} lg={5}>
                                        <div className='selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4'>                                    
                                         {/*    <label>Plant Name</label> */}
                                            <Form.Select onChange={changePlant} aria-label="Default select example">
                                                {plantList.map((val, i)=>{
                                                        return(
                                                            <option value={val.plant} key={i}>{val.plant}</option>
                                                        )
                                                    })}                               
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={3}>
                                        <div className='selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4'>
                                            <label>Year</label>
                                            <Form.Select onChange={changeYear} aria-label="Default select example">
                                                {yearList.map((val, i)=>{
                                                        return(
                                                            <option value={val.id} key={i}>{val.year}</option>
                                                        )
                                                    })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={3}>
                                    <Button variant="success" onClick={setData} className="active btn_lg text-center">Submit</Button>
                                        {/* <div className='selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4'> 
                                            <label>Month</label>
                                            <Form.Select onChange={changeMonth} aria-label="Default select example">
                                                {monthList.map((val, i)=>{
                                                        return(
                                                            <option value={val.id} key={i}>{val.month}</option>
                                                        )
                                                    })}                               
                                            </Form.Select>
                                        </div> */}
                                    </Col>
                                </Row>
                                
                                {pdfList.length > 0 && (
                                    <div className="per_hig">
                                        <div className="card_round mb-5">
                                            <Row className="justify-content-center mb-3">
                                                {pdfList.map((pdf, i)=>{
                                                    let pdfName = pdf.report_pdf.split("/");
                                                    return(
                                                    <Col key={i} className="col-xl-4" md={5}>
                                                        <a href={imgUrl + pdf.report_pdf} target="_blank" rel="noreferrer">
                                                           <Card className="wow fadeInUp" data-wow-delay="0.5s">
                                                               <Card.Title>{pdfName[2]}</Card.Title>
                                                               <Card.Img src={pdfIcon} />
                                                            </Card>
                                                        </a>
                                                    </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    </div>
                                )}
                            </>
                        }

                        {(status === 404 || pdfList.length ===0) && (
                            <Alert variant="danger">
                                <Alert.Heading>Data Not Available</Alert.Heading>
                          </Alert>
                        )}
                        </div>
                    </Container>
                </div>
            )) || ''}
            </>
        )
}

const mapStatetoProps = (state) => {
	return {
        environment: state.environmentR.environment,
        environmentsearch: state.environmentR.environmentsearch
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
      environmentApi: function (type) {
			dispatch(environmentApi(type));
		},
      environmentsearchApi: function (year, month, plant) {
			dispatch(environmentsearchApi(year, month, plant));
		}
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Environment);
