import React, {useState, useEffect} from "react";
import {Modal,Button} from 'react-bootstrap';
import Slider from "react-slick";
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/our-cement-banner.png'
import bannermobile from '../assets/images/our-cement-banner-mobile.jpg';
import aboutBannerLogo from '../assets/images/our-cement-icon.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import stand1icon from '../assets/images/stand2icon.png'
import stand2icon from '../assets/images/stand3icon.png'
import stand3icon from '../assets/images/stand1icon.png'
import standcement from '../assets/images/standcement.png'
import cementLogo from '../assets/images/cement_logo.svg';
import dcement from '../assets/images/dcement.png';
import dbull from '../assets/images/dbull.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import concreto01 from '../assets/images/concreto.jpg'
import Infracem50kg from '../assets/images/Infracem50kg.png';
import ppcpscprocem from '../assets/images/ppcpsc-procem.png';
import ppc43 from '../assets/images/ppc43.png';
import ppc53 from '../assets/images/ppc43.png';

import green from '../assets/images/green.jpg'
import duraguard50kg from '../assets/images/duraguard50kg.png';
import duraguardmicrofibre50kg from '../assets/images/duraguardmicro-fibre-50kg.png';
import duraguardrapidx50kg53 from '../assets/images/duraguard-rapidx50kg-53.png';
import duraguardxtra50kg from '../assets/images/duraguard-xtra-50kg.png';
import duraguardwaterseal50kg from '../assets/images/duraguard-waterseal-50kg.png';
import duraguardsilver50kg from '../assets/images/duraguard-silver-50kg.png';
import masterpremiumcement from '../assets/images/masterpremiumcement.png';
import DuraguardSilver from '../assets/images/duraguard-silver.png';
import concretoUno from '../assets/images/Concreto-uno-50kg.png';


import ppcbag from '../assets/images/ppcbag.png';
import pscbag from '../assets/images/pscbag.png';
import subhpremiumpsc from '../assets/images/subhpremiumpsc.png';
import pscadstarbags from '../assets/images/pscadstarbags.png';
import nirmax50kg from '../assets/images/nirmax50kg.png';
import nirmaxbags01 from '../assets/images/nirmaxbags01.png';
import { cementApi } from '../redux';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

const settings = {
  //centerMode: true,
  //autoplay:true,
  autoplay:true,
  autoplaySpeed: 2000,
  dots: false,
  arrow:true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
          },
      },
  ],
};


const OurCement = ({cementApi, cement}) =>{
    useEffect(() => {
        cementApi('product-cement-portfolio');        
    }, []);

    console.log(cement)
    if (Object.keys(cement).length) {
		var data = { ...cement.data };
        console.log(data)
	}

  const [one, setOne] = useState(false);
    const closeOne = () => setOne(false);

    const [two, setTwo] = useState(false);
    const closeTwo = () => setTwo(false);

    const [three, setThree] = useState(false);
    const closeThree = () => setThree(false);

    const [four, setFour] = useState(false);
    const closeFour = () => setFour(false);

    const [five, setFive] = useState(false);
    const closeFive = () => setFive(false);

    const [six, setSix] = useState(false);
    const closeSix = () => setSix(false);

    const showComponent=((varname) =>{    
      switch (varname) {
        case "one":
          setOne(true);
          break;
          case "two":
          setTwo(true);
          break;
          case "three":
              setThree(true);
              break;
              case "four":
              setFour(true);
              break;
              case "five":
              setFive(true);
              break;
              case "six":
              setSix(true);
              break;
                            
        default: return;
      }
    })

    return(
        <div className="aboutus-pge inner_page">
    <Helmet>
     <title>Top Cement Companies in India | Nuvoco Cement</title>
     <meta name="keywords" description="" />
     <meta name="description" content="Build your home with India's best cement manufacturing company, It offers widest quality of cement products like concreto,Nirmax, duraguard, double bull cement & infracem" />
     <link rel="canonical" href="https://nuvoco.com/top-cement-company"/>
    </Helmet>
        <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />
          <article className='position-absolute text-center'>
            <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
            <h1 className="text-uppercase">Top Cement Company in India</h1>
          </article>
          <div className='breadouter'>
            <div className='container'>
              <Breadcrumb className='mb-0 py-2'>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                Cement
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className='midpart'>
          <Container>
            <h2 className='grad-heading'>We come under top cement company in India</h2>
          <p>
          Nuvoco Vistas Corp. Ltd. is the fifth-largest cement group in India and the leading cement company in the East, in terms of capacity. Our 11 Cement Plants in the states of West Bengal, Bihar, Odisha, Chhattisgarh and Jharkhand in East India and Rajasthan and Haryana in North India comprising five integrated units (well equipped with Waste Heat Recovery systems and Captive Power Plants), five grinding units and one blending unit.
          </p>
          <p>
            The cement business accounts for ~84% of the company’s total sales. We are dominant in east with a good presence in North India in terms of capacity, and we feel proud to come under top cement companies in India.
         </p>
         <p>
            Our Cement product portfolio comprising Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem addresses the complete spectrum of customers with Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC), which are among the best in the industry based on BIS standards using premium quality raw material. Concreto is our premium product offering and a frontrunner in Eastern India. It also offers a specialized variant for Northern India. Duraguard offers a vast range of products that are technologically advanced and meet the modern construction requirements. Its range includes Duraguard WaterSeal Cement, which received the first-ever Patent Certificate for its Water-Resistant Cement Composition. Double Bull is the fastest growing cement brand in the country that has been indigenously developed and has achieved a sale of over 5 million tons in a short span of five years.
            </p>
            <p>
            Our commitment towards sustainability helps us focus on increasing the consumption of alternative fuel, promoting a healthy share of blended cement, and conserving natural resources through efficient Waste Heat Recovery systems, making a positive impact on the environment. Being leading cement suppliers in India, we are recognized as one of the leading players in the industry that manufactures products with high cementitious additions to achieve a competitive clinker-to-cement ratio.
            </p>
           
            <div className="vimi-page mt-5">
            <h2 className='grad-heading'>What Makes Us Stand Out?</h2>
            <Row className='topbx stand-page my-5'>
            <div className="vimiicon pb-2 d-flex justify-content-center">
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={stand1icon} />
                  </div>
                  <Card.Body>
                    
                    <Card.Text>
                    Innovations from our in-house experts
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={stand2icon} />
                  </div>
                  <Card.Body>
                    
                    <Card.Text>
                    Use of premium quality raw material
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={standcement} />
                  </div>
                  <Card.Body>
                   
                    <Card.Text>
                    Best BIS standards rating
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              </div>
            </Row>
        </div>
        <div className="our_brand_inner">
            <div className="card_round pt-5">
                <Container>
                    <h2 className='grad-heading pd-5 mb-5'>Our Brands</h2>
                    <Row className="pb-4">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('one')}>
                            <Card.Img src={cementLogo} />
                                <Card.Body>
                                    <article>
                                    Nuvoco’s premium Concreto range offers products that are carefully created after incorporating the latest research and development. Concreto is a frontrunner in Eastern India.
                                    {/* Nuvoco’s premium concreto range offers products that are carefully created after incorporating the latest research and development specializing in low water consumption and other eco-friendly variants. Concreto is a frontrunner in Eastern India and has a specialized variant for Northern India. */}
                                    </article>
                                    <Card.Title className="g_invert">
                                        Concreto
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('two')}>
                            <Card.Img src={dcement} />
                                <Card.Body>
                                    <article>
                                        Nuvoco’s flagship brand Duraguard is one of the most popular cement brands in the Northern and Eastern markets of India and has become a symbol of trust and longevity. It includes a portfolio of technologically advanced products for modern construction requirements.
                                    </article>
                                    <Card.Title className="g_invert">
                                    Duraguard
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('three')}>
                            <Card.Img src={dbull} />
                                <Card.Body>
                                    <article>
                                    Nuvoco’s latest brand Double Bull Cement is known as one of the fastest-growing cement brands in its operating markets. It includes a range of premium products like Master (PPC) and SUBH (PSC) along with variants for trade and institutional sales that are manufactured in technologically advanced plants using high- grade clinker.
                                    </article>
                                    <Card.Title className="g_invert">
                                    Double Bull
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('four')}>
                            <Card.Img src={psc} />
                                <Card.Body>
                                    <article>
                                    Introduced in 1993, Premium Slag Cement is the oldest offering from the house of Nuvoco created from a unique mix of ingredients providing unparalleled strength, excellent shine and smooth finish.
                                    </article>
                                    <Card.Title className="g_invert">
                                   PSC
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('five')}>
                            <Card.Img src={nirmex} />
                                <Card.Body>
                                    <article>
                                    Nirmax was launched in 2014 in the northern markets of India with a strong presence in Rajasthan. It has become a reliable brand for home builders in North India due to its highest ethical standards (it undergoes stringent quality checks at every level of production) and the latest technology. 
                                    </article>
                                    <Card.Title className="g_invert">
                                    Nirmax
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('six')}>
                            <Card.Img src={infracem} />
                                <Card.Body>
                                    <article>
                                    Infracem is best suited for heavy construction work including mass concreting, foundations and RCC work. It offers consistent quality and delivers high performance in infrastructure projects.
                                    </article>
                                    <Card.Title className="g_invert">
                                    Infracem
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                   
            </Container >
            </div>
        </div>
          </Container>
        </div>

    <Modal  size="lg" centered="true"
        show={one} onHide={closeOne} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
        <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={cementLogo} alt='' />
                        <article className="p-4">
                        Nuvoco’s premium Concreto range offers products that are carefully created after incorporating the latest research and development. Concreto is a frontrunner in Eastern India.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concreto01} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-cement">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoUno} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Uno
                            </Card.Title>
                            <Button variant="success" className="read_more" href="/products/concreto-uno">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={two} onHide={closeTwo} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={dcement} alt='' />
                        <article className="p-4">
                        Nuvoco’s flagship brand Duraguard is one of the most popular cement brands in the Northern and Eastern markets of India and has become a symbol of trust and longevity. It includes a portfolio of technologically advanced products for modern construction requirements.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

 <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguard50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Duraguard Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/duraguard-cement">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardmicrofibre50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Microfiber Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/duraguard-microfiber-cement">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardrapidx50kg53} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            RapidX Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/duraguard-rapidx-cement">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardxtra50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Xtra Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/duraguard-xtra-cement">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardwaterseal50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Waterseal Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/duraguard-waterseal-cement">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={DuraguardSilver} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            F2F
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/duraguard-f2f">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={three} onHide={closeThree} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={dbull} alt='' />
                        <article className="p-4">
                        Nuvoco’s latest brand Double Bull Cement is known as one of the fastest-growing cement brands in its operating markets. It includes a range of premium products like Master (PPC) and SUBH (PSC) along with variants for trade and institutional sales that are manufactured in technologically advanced plants using high- grade clinker.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={masterpremiumcement} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Master Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/double-bull-master-cement">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={ppcbag} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            PPC
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/double-bull-ppc">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={pscbag} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            PSC
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/double-bull-psc">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={subhpremiumpsc} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Subh Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" >Read More </Button> 
                            {/* <Button variant="success" className="read_more" href="/products/double-bull-subh-cement">Read More </Button>  */}
                        </Card.Body>
                    </Card>
                </Col>

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={four} onHide={closeFour} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={psc} alt='' />
                        <article className="p-4">
                        Introduced in 1993, Premium Slag Cement is the oldest offering from the house of Nuvoco created from a unique mix of ingredients providing unparalleled strength, excellent shine and smooth finish.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={pscadstarbags} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            PSC
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="products/p-s-c">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
               
                </Col>
                <Col md={4}>
              
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={five} onHide={closeFive} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={nirmex} alt='' />
                        <article className="p-4">
                        Nirmax was launched in 2014 in the northern markets of India with a strong presence in Rajasthan. It has become a reliable brand for home builders in North India due to its highest ethical standards (it undergoes stringent quality checks at every level of production) and the latest technology.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmax50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Nirmax Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nirmax-ppc">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmaxbags01} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Nirmax 43/53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nirmax-opc">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={six} onHide={closeSix} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={infracem} alt='' />
                        <article className="p-4">
                        Infracem and Procem are best suited for heavy construction work including mass concreting, foundations and RCC work. It offers consistent quality and delivers high performance in infrastructure projects.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={Infracem50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Infracem
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/infracem-cement">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppcpscprocem} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Procem
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-procem-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppc43} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 43
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-43">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                {/* <Col md={4}>
                                <Card>
                                        <Card.Img src={ppc53} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-53">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col> */}

                            </Slider>
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

      </div >
    )
}

const mapStatetoProps = (state) => {
	return {
		cement: state.cementR.cement,
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
		cementApi: function (page_url) {
			dispatch(cementApi(page_url));
		},
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(OurCement);